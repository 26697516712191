export const appearance = {
  theme: 'stripe',
  variables: {
    colorPrimary: '#e51937',
    colorBackground: '#ffffff',
    colorText: '#30313d',
    colorDanger: '#df1b41',
    fontFamily: 'museo-sans, sans-serif',
    spacingUnit: '4px',
    borderRadius: '0',
    colorSuccess: 'green',
    spacingAccordionItem: '500px'
  },
  rules: {
    '.AccordionItem': {
      border: '1px solid #ccc',
    },
    '.Input': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: '1px solid #cccccc',
      padding: '10px 12px',
      fontSize: '13px',
      lineHeight: '15px',
    },
    '.Label': {
      fontFamily: 'museo-sans, sans-serif',
      fontSize: '14px',
      fontWeight: '300',
      color: 'rgb(102, 102, 102)'
    },
    '.PickerItem': {
      fontFamily: 'museo-sans, sans-serif',
      fontSize: '14px',
      fontWeight: '300',
      color: 'rgb(102, 102, 102)'
    }
  }
}